import React from 'react';
import Container from '@material-ui/core/Container';
import Layout from "../components/Layout";
import beeImage from "../images/bee.png"
import SiteHelmet from "../components/SiteHelmet";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  image: {
    flex: 1,
    width: "100%",
    height: "100%",
    maxWidth: "437px",
    maxHeight: "651px",
    resizeMode: "contain"
  }
}));

const Index = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SiteHelmet title="Home"/>
      <Container maxWidth="sm">
        <img className={classes.image} src={beeImage} alt={"bee"} />
      </Container>
    </Layout>
  );
};

export default Index;